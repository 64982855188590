import React from 'react'
import { Link } from 'react-router-dom'
import Button from './generic/Button'
import { PANEL_LINK, panelLinks } from '../utils/consts'

const BurgerMenu = ({ style, forwardRef, visible, advantagesRef, feedbackRef, faqRef }) => {

    const scrollToAdvantages = () => advantagesRef.current.scrollIntoView()
    const scrollToFeedback = () => feedbackRef.current.scrollIntoView()
    const scrollToFaq = () => faqRef.current.scrollIntoView()

    return (
        <div
            
            style={style}
            className={`burger__menu ${visible ? 'visible' : 'not-visible'}`}
        >
            <div className='burger__content'>
                <div className="header__btns">
                    <Button onClick={() => {ym(91389906,'reachGoal','btn-panel'); window.location.assign(panelLinks.login)}} className={'button_light'} >Войти</Button>
                    <Button onClick={() => {ym(91389906,'reachGoal','btn-panel'); window.location.assign(panelLinks.register)}} >Регистрация</Button>
                </div>
                <ul className='navbar__menu'>
                    <li className='navbar__item'>
                        <Link to="/">Главная</Link>
                    </li>
                    <li onClick={scrollToAdvantages} className='navbar__item'>
                        Преимущества
                    </li>
                    <li onClick={scrollToFeedback} className='navbar__item'>
                        Отзывы
                    </li>
                    <li onClick={scrollToFaq} className='navbar__item'>
                        FAQ
                    </li>
                </ul>

            </div>
        </div>
    )
}

export default BurgerMenu
