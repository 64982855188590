import React, { forwardRef } from 'react'

import { SwiperSlide } from 'swiper/react'
import Slider from '../generic/Slider'

import './Feedback.css'

import moneyfrends from '../../assets/moneyfriends.svg'
import uvenco from '../../assets/uvenco.svg'
import msb_leasing from '../../assets/msb-leasing.svg'
import doctorlaw from '../../assets/doctorlaw.svg'
import kaplya from '../../assets/kaplya.svg'

import maxmarket from '../../assets/maxmarket.svg'
import hacktech from '../../assets/hacktech.svg'
import fursheat from '../../assets/fursheat.webp'


const Feedback = ({ forwardRef }) => {

  return (
    <section ref={forwardRef} className='feedback'>
      <div className="feedback__wrapper">
        <h2>что говорят о нас клиенты</h2>

        <div className='feedback__slider'>
          <Slider className='swiper-container' slidesPerView='auto'>

            <SwiperSlide key='1'>
              <div className='feedback__card border_left'>
                <div>
                  <img src={maxmarket} alt="" className='img' />
                </div>

                <span>@MaxMarket</span>
                <p>
                  "Для меня, как для маркетолога крупной компании,
                  было очень ценно найти сервис bloggery. С ним мне стало намного легче подбирать
                  проверенных блогеров, смотреть их статистику, связываться с менеджерами,
                  так как все это уже собрано в одном месте. На все мои вопросы я получала
                  быстрые и полезные ответы, за что хочу выразить
                  большую благодарность их команде.
                  Особенно приятно, что есть гарант сделки, и можно не волноваться на счет
                  крупных сумм, которые компания инвестирует в продвижение.
                  Спасибо за сотрудничество!"
                  <p style={{ fontStyle: 'normal', marginTop: '0.4em' }}>
                    С уважением, Мария, маркетолог.
                  </p>
                </p>

              </div>
            </SwiperSlide>

            <SwiperSlide key='2'>
              <div className='feedback__card column-reverse'>
                <div>
                  <img src={moneyfrends} alt="" className='img' />
                </div>

                <span>@МАНИФРЕНДС</span>
                <div>
                  <p>
                    "Наша компания работает с инвестициями - нужных блогеров искать затруднительно.
                  </p>
                  <p>
                    Менеджер сервиса Bloggery быстро нам помог, самостоятельно с ними связался,
                    рассказал нам все условия, после чего мы согласились начать рекламную кампанию.
                  </p>
                  <p>
                    Это привело к увеличению регистраций и привлечению инвестиций через нашу платформу. Большое спасибо команде, уже планируем дату следующей рекламной кампании!"
                  </p>
                  <p style={{ fontStyle: 'normal', marginTop: '0.4em' }}>
                    Юрий, инвестор, бизнесмен.
                  </p>
                </div>

              </div>
            </SwiperSlide>

            <SwiperSlide key='3'>
              <div className='feedback__card border_right'>
                <div>
                  <img src={uvenco} alt="" className='img' />
                </div>

                <span>@ЮВЕНКО</span>
                <p>
                  "Мы продаем кофе и продукты питания через вендинговые автоматы,
                  которые расположены в университетах, торговых центрах, жд-станциях и т.д..
                  Целью нашей рекламной кампании было донести до потребителя, что кофе из автоматов
                  является хорошим аналогом кофейни. Мы брали тариф с менеджером, который подобрал нам
                  блогеров и помог составить ТЗ на основе наших презентаций.
                  Результат - реклама получилась качественной и эффективной,
                  продажи увеличились на 25% со всех автоматов по городу. Особенно понравилось,
                  что сервис выступает гарантом сделок, а также быстро работает служба поддержки!
                  Спасибо Bloggery!"
                  <p style={{ fontStyle: 'normal', marginTop: '0.4em' }}>
                    Елена, менеджер по продажам.
                  </p>
                </p>

              </div>
            </SwiperSlide>

            <SwiperSlide key='4'>
              <div className='feedback__card column-reverse' >
                <div>
                  <img src={hacktech} alt="" className='img' />
                </div>

                <span>@HackTech</span>
                <p>
                  "Меня приятно порадовало, что команда сервиса помогает подобрать
                  блогеров даже для компаний со сложной целевой аудиторией, благодаря
                  чему наша реклама дала большие результаты. Платформа удобная, экономит время.
                  Отдельно хочу отметить безопасность сделок, так как мы брали большой бюджет,
                  возможность регистрации рекламы в ОРД без моего участия. Менеджеры сайта
                  развеяли все мои сомнения, предоставили отзывы и ответили на все вопросы,
                  за что им отдельное спасибо."
                  <p style={{ fontStyle: 'normal', marginTop: '0.4em' }}>
                    Hakob, CEO.
                  </p>
                </p>

              </div>
            </SwiperSlide>

            <SwiperSlide key='5'>
              <div className='feedback__card border_left'>
                <div>
                  <img src={msb_leasing} alt="" className='img' />
                </div>

                <span>@МСБ-ЛИЗИНГ</span>
                <p>
                  "Для нас, как крупной компании, была важна работа на результат и сохранность бюджета.
                  Менеджеры сервиса Bloggery смогли в сжатые сроки подобрать блогеров и составить ТЗ
                  нашей рекламной кампании. Как итог, у нас увеличилось количество заявок в 1,5 раза!
                  В ходе работы менеджеры предоставляли всю статистику и советовали, как можно улучшить работу
                  в следующий раз, чего я не видела на других платформах!
                  Отдельно хочу отметить прозрачность сделок. Спасибо!"
                  <p style={{ fontStyle: 'normal', marginTop: '0.4em' }}>
                    Юлия, директор по маркетингу.
                  </p>
                </p>
              </div>
            </SwiperSlide>

            <SwiperSlide key='6'>
              <div className='feedback__card column-reverse'>
                <div>
                  <img src={kaplya} alt="" className='img' />
                </div>

                <span>@КАПЛЯ</span>
                <p>
                  "В первый раз покупала рекламу у блогера, не понимала как это правильно сделать...
                  На сайте все настолько просто и удобно, что я до сих пор под впечатлением!
                  Доступна вся информация о блогере, сайт даже составил мне ТЗ рекламной подачи у блогера!
                  Самое главное - потратила на рекламу 3000р., а заработала 15 000р.!
                  Такого не было даже, когда с таргетом работала! Спасибо!"
                  <p style={{ fontStyle: 'normal', marginTop: '0.4em' }}>
                    Александра, предприниматель.
                  </p>
                </p>

              </div>
            </SwiperSlide>

            <SwiperSlide key='7'>
              <div className='feedback__card border_right'>
                <div>
                  <img src={doctorlaw} alt="" className='img' />
                </div>
                <span>@doctorlaw</span>
                <p>
                  "Я давно занимаюсь закупкой рекламы у блогеров, постоянно это занимает много времени:
                  вручную проверить всю статистику и историю отзывов о блогере,
                  связаться с менеджером и т.д Здесь собраны все данные о блогере в одном месте,
                  что значительно экономит время! Отдельно хочу отметить, что все сделки прозрачны
                  и на сайте нет мошенников, всегда можно открыть спор и нет риска остаться без денег.
                  Спасибо за обратную связь команде - отвечают быстро и эффективно!
                  Буду рекомендовать коллегам!"
                  <p style={{ fontStyle: 'normal', marginTop: '0.4em' }}>
                    Анна, smm специалист.
                  </p>
                </p>

              </div>
            </SwiperSlide>

            <SwiperSlide key='8'>
              <div className='feedback__card column-reverse' style={{ justifyContent: 'start' }}>
                <div>


                  <img src={fursheat} alt="" className='img' />
                </div>
                <span>@Fursheat</span>
                <p>
                  "Развиваем кейтеринг готовой еды в боксах,
                  нужны были новые клиенты. Таргет
                  в социальных сетях перестал работать,
                  решили попробовать у блогеров напрямую
                  рекламироваться. Блогер-повар сделал
                  все очень профессионально, теперь думаем
                  о следующей рекламной кампании."
                </p>
              </div>
            </SwiperSlide>

            <SwiperSlide key='9'>
              <div className='feedback__card border_left' >
                <div>
                  <img alt="" className='img' />
                </div>

                <span></span>
                <p>
                  "Это просто находка для меня! С помощью сервиса увеличила в своем небольшом
                  блоге количество заявок на рекламу в 2 раза. Очень удобная платформа, экономит время!
                  Рекламодатели сами пишут свои предложения, не нужно
                  предоставлять всю свою статистику несколько раз!"
                  <p style={{ fontStyle: 'normal', marginTop: '0.4em' }}>
                    Юлия, блогер.
                  </p>
                </p>

              </div>
            </SwiperSlide>

          </Slider>
        </div>

      </div>
    </section>
  )
}

export default Feedback
