import React, { useEffect, useRef } from 'react'

import './LawInfo.css'

const LawInfo = ({ show, onHide }) => {

    const ref = useRef()
    const handleClose = (e) => {
        if (!e) {
            onHide()
        }

        console.log(ref.current);
        console.log(e.target);
        if (ref.current && !ref.current.contains(e.target)) {
            onHide()
        }
    }

    return (
        <div onClick={(e) => handleClose(e)} style={show ? {} : { display: 'none' }} className='discount-widget'>

            <div ref={ref} className='discount-widget__content'>
                <div onClick={(e) => handleClose(e)} className='content' >
                    <button onClick={() => handleClose()} className='discount-widget__close button button_light'>
                        <span>X</span>
                    </button>

                    <div className='discount-widget__flex-container'>
                        <div className='discount-widget__header'>
                            <h3>Юридическая информация</h3>
                        </div>
                        <div className='discount-widget__body'>
                            <div>
                                <span>Наименование компании: </span>
                                Общество с ограниченной ответственностью «ДОНАТФАНС»
                            </div>
                            <div>
                                <span>Юридический адрес: </span>
                                119619, город Москва, ул 6-Я Лазенки, д. 32, кв. 6
                            </div>
                            <div>
                                <span>ИНН: </span>
                                9729340040
                            </div>
                            <div>
                                <span>ОГРН: </span>
                                1237700106973
                            </div>
                            <div>
                                <span> Электронная почта: </span>
                                <a href='mailto:support@bloggery.io'>support@bloggery.io</a> (в том числе для направления заявления согласно
                                ч. 2 ст. 10 Федерального закона от 27.07.2006 г. N 149-ФЗ «Об информации,
                                информационных технологиях и о защите информации»)
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default LawInfo
