import React from 'react'

import './Logo.css'
import { PANEL_LINK } from '../utils/consts'
import { useNavigate } from 'react-router-dom'

const Logo = ({onClick, style}) => {

  const navigate = useNavigate()

  return (
    <span onClick={onClick || (() => navigate('/'))} className='logo' style={style}>
        Bloggery
    </span>
  )
}

export default Logo
