import React, { useRef, useState } from 'react'

import Logo from './Logo'
import Button from './generic/Button'

import './Header.css'
import { Link } from 'react-router-dom'
import { PANEL_LINK, panelLinks } from '../utils/consts'


const Header = ({ visible, forwardRef, toggleBurgerVisible, advantagesRef, feedbackRef, faqRef }) => {

    const ref = useRef()

    const scrollToAdvantages = () => advantagesRef.current.scrollIntoView()
    const scrollToFeedback = () => feedbackRef.current.scrollIntoView()
    const scrollToFaq = () => faqRef.current.scrollIntoView()

    return (
        <header ref={forwardRef} className='header'>
            <span className='title__light' />
            <div className="header__wrapper">

                <nav className='navbar'>
                    <ul className='navbar__menu'>
                        <li className='navbar__item'>
                            <Link to="/">Главная</Link>
                        </li>
                        <li onClick={scrollToAdvantages} className='navbar__item'>
                            Преимущества
                        </li>
                        <li onClick={scrollToFeedback} className='navbar__item'>
                            Отзывы
                        </li>
                        <li onClick={scrollToFaq} className='navbar__item'>
                            FAQ
                        </li>
                    </ul>
                </nav>

                <div className='header__line'>
                    <Logo />
                </div>

                <div className="header__btns">
                    <Button onClick={() => {ym(91389906,'reachGoal','btn-panel'); window.location.assign(panelLinks.login)}} className={'button_light'} >Войти</Button>
                    <Button onClick={() => {ym(91389906,'reachGoal','btn-panel'); window.location.assign(panelLinks.register)}} >Регистрация</Button>
                </div>

                <div onClick={() => toggleBurgerVisible(ref)} className='burger__btn'>
                    <span /><span style={visible ? { marginLeft: '-6px' } : { marginLeft: '0' }} /><span />
                </div>

            </div>

        </header>
    )
}

export default Header
