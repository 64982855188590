import React from 'react'

import { useSpring, animated } from 'react-spring'

const AnimatedNumber = ({ n }) => {
    const { number } = useSpring({
        from: { number: n - 10 },
        number: n + 10,
        delay: 1000,
        config: { mass: 1, tension: 20, friction: 10 },
    });

    return (
        <animated.span>{number.to(x => x.toFixed(0))}</animated.span>
    )
}

export default AnimatedNumber
