import React from 'react'
import { useSpring, animated } from 'react-spring'

const AppearFromY = ({ dataRef, translateFrom, delay, duration, children, className }) => {

    const isRef = dataRef == 'none' ? true : dataRef?.isIntersecting ? true : false

    const styles = useSpring({
        from: {
            opacity: 0,
            transform: `translateY(${translateFrom || '50px'})`
        },
        to: {
            opacity: isRef ?  1 : 0,
            transform: isRef ? `translateY(0px)` : `translateY(${translateFrom || '50px'})`
        },
        delay: delay || 500,
        config: { duration: duration || 500 }
    });

    return (
        <animated.div className={className} style={styles}>{children}</animated.div>
    )
}

export default AppearFromY
