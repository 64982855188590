import React from 'react'
import './styles.css'

const ChecklistWrapper = ({children}) => {
  return (
    <div className="checklist">
      <div className="checklist__wrapper">{children}</div>
    </div>
  )
}

export default ChecklistWrapper
