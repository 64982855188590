import React, { Suspense, lazy, useCallback, useEffect, useState } from 'react'

import './Title.css'

import arrow from '../assets/arrows.webp'
import titleImg from '../assets/title_img.webp'
import titleBlogger from '../assets/title_blogger.webp'
import titleAdvertiser from '../assets/title_advertiser.webp'

import Button from './generic/Button'
import { useNavigate, Link } from 'react-router-dom'
import { PANEL_LINK, panelLinks } from '../utils/consts'

const Title = ({ calcRef, pageName, newPhrases }) => {

    const scrollToCalculator = () => calcRef.current.scrollIntoView()

    const navigate = useNavigate()

    const phrases = newPhrases || [
        'Объединяем', 'РЕКЛАМОДАТЕЛЕЙ', 'и БЛОГЕРОВ'
    ]

    const [loaded, setLoaded] = useState(false)
    const [currentId, setCurrentId] = useState(0);

    useEffect(() => {


    }, []);

    const [showImage, setShowImage] = useState(false);
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {

        const interval = window.setInterval(() => {
            setCurrentId(currentId => (currentId + 1) % (pageName == 'blogger' ? 4 : 3))
        }, 2000);

        const image = new Image();
        image.onload = () => {
            setShowImage(true);
        }
        image.src = pageName == 'main' ? titleImg : titleBlogger;

        return () => {
            window.clearInterval(interval);
            image.onload = null;
        };
    }, []);

    return (
        <section className='title'>

            <div className='title__wrapper'>
                <div className='title__container'>
                    <div className='title__content'>



                        {pageName == 'main' &&
                            <div className='advertiser title__headers'>
                                <img src={arrow} alt={''} className='title__decor_arrow' />
                                <h1>BLOGGERY.IO</h1>
                                <h2>
                                    Реклама у блогеров<br />
                                    без слива
                                    <span style={{ position: 'relative', fontSize: 'inherit' }}>
                                        <span style={{ fontSize: 'inherit' }} className='title__decor_rect'> бюджета</span>
                                    </span>
                                </h2>
                            </div>
                        }
                        {pageName == 'blogger' &&
                            <div className='blogger title__headers'>
                                <img src={arrow} alt={''} className='title__decor_arrow' />
                                <h1>BLOGGERY.IO</h1>
                                <h2>
                                    С нами зарабатывает<br />
                                    каждый
                                    <span style={{ position: 'relative', fontSize: 'inherit' }}>
                                        <span style={{ fontSize: 'inherit' }} className='title__decor_rect'> блогер</span>
                                    </span>

                                </h2>
                            </div>
                        }

                        {pageName == 'advertiser' &&
                            <div className='advertiser title__headers'>
                                <img src={arrow} alt={''} className='title__decor_arrow' />
                                <h1>BLOGGERY.IO</h1>
                                <h2>
                                    Реклама у блогеров<br />
                                    без слива
                                    <span style={{ position: 'relative', fontSize: 'inherit' }}>
                                        <span style={{ fontSize: 'inherit' }} className='title__decor_rect'> бюджета</span>
                                    </span>
                                </h2>
                            </div>
                        }

                        <div className='title__unite'>
                            <span className='title__unite-decor' />

                            <span className='title__unite-text'>
                                {phrases[currentId]}
                            </span>

                        </div>
                        <div className='desktop title__btns-block'>
                            {pageName == 'main' ?
                                <div className='title__btns'>
                                    <Link to={'/blogger'} title='С нами зарабатывает каждый блогер'><Button className='button_reverse' >БЛОГЕРУ</Button></Link>
                                    <Link to={'/advertiser'} title='Реклама у блогеров без слива бюджета'><Button>РЕКЛАМОДАТЕЛЮ</Button></Link>
                                </div>
                                :
                                <div className='title__btns'>
                                    <Button onClick={() => { ym(91389906, 'reachGoal', 'btn-panel'); window.location.assign(panelLinks.register) }} >зарегистрироваться</Button>
                                </div>
                            }

                            <span onClick={scrollToCalculator} className='title__calc-btn'>
                                {pageName != 'blogger' ?
                                    'рассчитать стоимость размещения'
                                    :
                                    'рассчитать доход'
                                }

                            </span>
                        </div>
                    </div>



                    {pageName == 'main' && !showImage ?
                        <div className='title__img_preloader-container'>
                            <span className='spinner' />
                        </div>
                        :
                        <div className={`title__img-container ${pageName == 'main' ? '' : 'blogger'}`}>
                            <img
                                onLoad={() => setIsMounted(true)}
                                className={`img ${isMounted ? 'animate' : 'd-none'}`}
                                src={pageName == 'main' ? titleImg : pageName == 'blogger' ? titleBlogger : titleAdvertiser}
                                alt=' '
                            />
                        </div>
                    }

                </div>

                <div className='mobile title__btns-block'>
                    {pageName == 'main' ?
                        <div className='title__btns'>
                            <Button className='button_reverse' onClick={() => navigate('/blogger')}>БЛОГЕРУ</Button>
                            <Button onClick={() => navigate('/advertiser')} >РЕКЛАМОДАТЕЛЮ</Button>
                        </div>
                        :
                        <div className='title__btns'>
                            <Button onClick={() => { ym(91389906, 'reachGoal', 'btn-panel'); window.location.assign(panelLinks.register) }} >зарегистрироваться</Button>
                        </div>
                    }
                    <span onClick={scrollToCalculator} className='title__calc-btn'>
                        {pageName != 'blogger' ?
                            'рассчитать стоимость размещения'
                            :
                            'рассчитать доход'
                        }

                    </span>
                </div>



            </div>


            {
                pageName &&
                <div className='title__stats'>

                    <div>
                        <div className='title__stats__num'>20000</div>
                        <div className='title__stats__income'><span style={{ fontWeight: '900' }}>+ 1289</span> за месяц</div>
                        <div className='title__stats__text'>блогеров сотрудничают с нами</div>
                    </div>
                    <div className="title__stats__border" />
                    <div>
                        <div className='title__stats__num'>5000</div>
                        <div className='title__stats__income'><span style={{ fontWeight: '900' }}>+ 1148</span> за месяц</div>
                        <div className='title__stats__text'>рекламодателей на платформе</div>
                    </div>
                    <div className="title__stats__border" />
                    <div>
                        <div className='title__stats__num'>4000</div>
                        <div className='title__stats__income'><span style={{ fontWeight: '900' }}>+ 1193</span> за месяц</div>
                        <div className='title__stats__text'>реализованных заказов</div>
                    </div>
                </div>
            }
            {/* 
            {pageName == 'blogger' &&
                <div className='blogger title__stats'>
                    <div>
                        <div className='title__stats__text'>Получаете реальные деньги<br />с быстрым выводом;</div>
                    </div>
                    <div className="title__stats__border" />
                    <div>
                        <div className='title__stats__text'>Находим рекламодателей<br />самостоятельно за вас; </div>
                    </div>
                    <div className="title__stats__border" />
                    <div>
                        <div className='title__stats__text'>Ускоряете свою коллаборацию<br />с другими известными блогерами;</div>
                    </div>
                    <div className="title__stats__border" />
                    <div>
                        <div className='title__stats__text'>Получаете автоматические оповещения<br />о новых рекламных предложениях;</div>
                    </div>
                </div>
            }
            {pageName == 'advertiser' &&
                <div className='advertiser title__stats'>
                    <div>
                        <div className='title__stats__text'>Экономите деньги, реклама<br />у блогеров дешевле таргета;</div>
                    </div>
                    <div className="title__stats__border" />
                    <div>
                        <div className='title__stats__text'>Получаете гарантию<br />честной сделки;</div>
                    </div>
                    <div className="title__stats__border" />
                    <div>
                        <div className='title__stats__text'>Предоставляем необходимые<br />документы для бухгалтерии;</div>
                    </div>
                    <div className="title__stats__border" />
                    <div>
                        <div className='title__stats__text'>Существует фильтр для выбора<br />необходимого вам блогера;</div>
                    </div>
                </div>
            }
            */}

        </section >
    )
}

export default Title
