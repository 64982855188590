import React from 'react'
import Button from '../components/generic/Button'
import Container from '../components/generic/Container';

const Error404 = () => {
    return (
        <Container>
            <div style={{ marginTop: '3em', width: '100%', height: '100%' }} className='column'>
                <div className='h1-like-title' style={{ marginBottom: '0.4em' }}>
                    404
                </div>
                <div style={{ fontSize: '1.5em', marginBottom: '1em' }}>
                    Ой... Кажется такой страницы не нашлось
                </div>
                <div style={{padding: '1em 0'}}>
                    <a href='/' className='button' >
                        На главную
                    </a>
                </div>
            </div>
        </Container>
    )
}

export default Error404
