import React from 'react'

import Button from './generic/Button'

import './End.css'

import img from '../assets/end_img.webp'
import { PANEL_LINK, panelLinks } from '../utils/consts'

const End = ({ pageName, forwardRef }) => {

    return (
        <section ref={forwardRef} className='end'>
            <div className='end__wrapper'>
                {pageName == 'main' &&
                    <h2>
                        размещайте <span className='text_gradient'>рекламу у блогеров</span><br />в два клика!
                    </h2>
                }
                {pageName == 'blogger' &&
                    <h2>
                        начни
                        <span className='text_gradient'> монетизировать</span><br />
                        <span className='text_gradient'>свой блог </span>
                        уже сейчас!
                    </h2>
                }
                {pageName == 'advertiser' &&
                    <div>
                        <div className='num'>20000</div>
                        <h2>
                            блогеров готовы
                            <span className='text_gradient'> увеличить</span><br />
                            <span className='text_gradient'>ваши продажи!</span>
                        </h2>
                    </div>
                }
                <div className='end__btns'>
                    <Button onClick={() => {ym(91389906,'reachGoal','btn-panel'); window.location.assign(panelLinks.register)}} className='button_light'>Зарегистрироваться</Button>
                    <Button onClick={() => {ym(91389906,'reachGoal','btn-panel'); window.location.assign(panelLinks.login)}}>{pageName != 'blogger' ? 'разместить' : 'забрать'} рекламу</Button>
                </div>
                <div className='end__img'>
                    <img className='img' src={img} alt="" />
                </div>
            </div>
        </section>
    )
}

export default End
