import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// import required modules
import { Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import './Slider.css'

const Slider = (props) => {
  return (
    <Swiper
      className={`slider ${(props.className || '')}`}
      slidesPerView={props.slidesPerView || 4}
      spaceBetween={props.spaceBetween || 25}
      centeredSlides={window.innerWidth > 680 ? false : true}
      modules={[Navigation]}
      navigation={true}
    >
      {props.children}
    </Swiper>
  );
}

export default Slider