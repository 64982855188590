import React from 'react'

import './AdvantagesCard.css'

const AdvantagesCard = ({className, title, text}) => {
  return (
    <div className={`AdvantagesCard column ${(className || '')}`} >
      <span dangerouslySetInnerHTML={{ __html: title }}></span>
        <hr />
        <p dangerouslySetInnerHTML={{ __html: text}}></p>
      
    </div>
  )
}

export default AdvantagesCard
