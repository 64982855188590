export const PANEL_LINK = 'https://panel.bloggery.io'
export const VK_LINK = 'https://vk.com/bloggeryio'
export const TELEGRAM_LINK = 'https://t.me/bloggeryio'
export const YANDEX_DZEN_LINK = 'https://dzen.ru/bloggeryio'
export const VCRU_LINK = 'https://vc.ru/u/2394585-bloggery-io-reklama-u-blogerov'
export const YAPPY_LINK = 'https://yappy.media/s/p_2itsqpLbHJ5ZZ6lLKAjxEe'
export const MAIL = 'info@bloggery.io'

export const panelLinks = {
    login: 'https://panel.bloggery.io/login',
    register: 'https://panel.bloggery.io/register'
}
