import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/Logo";

import arrows from "../../assets/arrows_purple.svg";
import img from "../../assets/checklist_finish.png";
import tgIcon from "../../assets/tg_icon.svg";
import vkIcon from "../../assets/vk_icon.svg";
import ChecklistWrapper from "../checklist/ChecklistWrapper";

export default function MistakesEnd() {
  const [source, setSource] = useState(() => localStorage.getItem("source"));

  useEffect(() => {
    const amochat = document.querySelector(".amo-button-holder");
    if (amochat) {
      amochat.classList.add('d-none-important')
    }
  });

  return (
    <ChecklistWrapper>
      <div className="checklist__main checklist__finish">
        <div className="desktop checklist__logo">
          <Logo
            style={{
              display: "inline-block",
              fontSize: "18px",
              cursor: "default",
            }}
          />
        </div>

        <div className="row space-between">
          <div className="checklist__leftside">
            <div className="mobile checklist__logo">
              <Logo
                style={{
                  display: "inline-block",
                  fontSize: "18px",
                  cursor: "default",
                }}
              />
            </div>
            <div className="checklist__title purple">
              Спасибо, материал
              <br />
              ждет вас в канале
            </div>
            <div className="checklist__subtitle">
              <span className="checklist__arrows">
                <img src={arrows} alt="" />
              </span>
              <div>
                Подпишитесь и получайте каждую
                <br />
                неделю новую подборку
              </div>
            </div>
            {source == "vk" ? (
              <a
                href="https://vk.com/app7336203_-217172766"
                className="desktop button button_rectangle button_purple"
                style={{
                  width: "290px",
                }}
                target="_blank"
              >
                подписаться
              </a>
            ) : (
              <a
                href="https://t.me/BloggeryMailingBot"
                className="desktop button button_rectangle button_purple"
                style={{
                  width: "290px",
                }}
                target="_blank"
              >
                <img
                  src={tgIcon}
                  alt=""
                  width={19}
                  height={17}
                  style={{ marginRight: "16px" }}
                />
                Telegram
              </a>
            )}
          </div>
          <div className="checklist__rightside">
            <span className="checklist__rightside__top-title">
              <span className="text_gradient">
                «10 маркетинговых
                <br />
                ошибок,{" "}
              </span>
              которые
              <br />
              влияют на продажи»
            </span>
            <div className="checklist__rightside__img-container">
              <img className="img" src={img} alt="" />
            </div>
            <span className="checklist__rightside__bottom-title">
              <span>mistake</span>
              <span>mistake</span>
              <span>mistake</span>
              <span>mistake</span>
              <span>mistake</span>
            </span>

            {source == "vk" ? (
              <a
                href="https://vk.com/app7336203_-217172766"
                className="mobile button button_rectangle button_purple"
                style={{
                  width: "290px",
                }}
              >
                подписаться
              </a>
            ) : (
              <a
                href="https://t.me/BloggeryMailingBot"
                className="mobile button button_rectangle button_purple"
                style={{
                  width: "290px",
                }}
              >
                <img
                  src={tgIcon}
                  alt=""
                  width={19}
                  height={17}
                  style={{ marginRight: "16px" }}
                />
                Telegram
              </a>
            )}
          </div>
        </div>
      </div>
    </ChecklistWrapper>
  );
}
