import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import './reset.css';
import './index.css';
import { BrowserRouter } from 'react-router-dom';

const loader = document.querySelector('.loader');

const showLoader = () => loader.classList.remove('loader__hide');
const hideLoader = () => loader.classList.add('loader__hide');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <App
    // hideLoader={hideLoader}
    />
  </BrowserRouter>
);


