import React, { useEffect, useState } from 'react'

import img from '../assets/user_flow.webp'
import mobile from '../assets/user_flow_mobile.webp'
import blogger from '../assets/user_flow_blogger.webp'

import Button from './generic/Button'
import AnimatedNumber from './generic/AnimatedNumber'

import './UserFlow.css'
import { PANEL_LINK, panelLinks } from '../utils/consts'

const UserFlow = ({ pageName, forwardRef }) => {

  const [bloggerNum, setBloggerNum] = useState(20000)
  const [advertiserNum, setAdvertiserNum] = useState(5000)

  useEffect(() => {
    const interval = window.setInterval(() => {
      setBloggerNum(bloggerNum => bloggerNum + 1)
      setAdvertiserNum(advertiserNum => advertiserNum + 1.5)
    }, Math.floor(Math.random() * (4000 - 2000 + 1) + 2000));
    return () => window.clearInterval(interval);
  }, []);

  return (
    <section ref={forwardRef} className='user-flow'>
      <div className='user-flow__wrapper'>

        <div className='user-flow__headers'>
          <h2>Как работает<br /> BLOGGERY.IO</h2>
          <h3>
            <span>BLOGGERY.IO - рекламный маркетплейс</span><br />
            <span>блогеров и рекламодателей</span>
          </h3>
        </div>

        <div className='user-flow__img'>
          {pageName != 'blogger' && <img className='desktop img' src={img} alt="" />}
          {pageName == 'blogger' && <img className='desktop img' src={blogger} alt="" />}

          <img className='mobile img' src={mobile} alt="" />
        </div>



        {pageName == 'main' && <div className="user-flow__text">

          <div className='user-flow__counters'>
            <div>
              <span className='user-flow__num purple'>
                <AnimatedNumber n={bloggerNum} />
              </span>
              <div>БЛОГЕРОВ</div>
            </div>
            <div>
              <span className='user-flow__num'>
                <AnimatedNumber n={advertiserNum} />
              </span>
              <div>РЕКЛАМОДАТЕЛЕЙ</div>
            </div>
          </div>

          <h4>сотрудничают на нашей платформе!</h4>
        </div>

        }

        {pageName == 'blogger' &&
          <div className='blogger user-flow__text'>



            <div className='user-flow__footer'>

              <div className='user-flow__counters'>
                <div>
                  <span className='user-flow__num'>
                    <AnimatedNumber n={advertiserNum} />
                  </span>
                  <div>рекламодателей готовы<br />разместить рекламу в твоем блоге</div>
                </div>
              </div>

              <h4>
                <span>регистрируйся и начни</span><br />
                <span className='purple'>монетизировать свой контент!</span>
              </h4>
              <div>
                <Button onClick={() => {ym(91389906,'reachGoal','btn-panel'); window.location.assign(panelLinks.register)}} >зарегистрироваться</Button>
              </div>
            </div>

          </div>
        }

        {pageName == 'advertiser' &&
          <div className='advertiser user-flow__text'>

            <div className='user-flow__footer'>
              <h4>сотрудничают на нашей платформе!</h4>
              <div>
                <Button onClick={() => {ym(91389906,'reachGoal','btn-panel'); window.location.assign(panelLinks.register)}} >зарегистрироваться</Button>
              </div>
            </div>

          </div>
        }


      </div>
    </section>
  )
}

export default UserFlow
