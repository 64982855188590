import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import arrows from "../../assets/arrows_purple.svg";
import img from "../../assets/checklist_main.png";
import ChecklistWrapper from "../checklist/ChecklistWrapper";
import Logo from "../../components/Logo";

const Mistakes = () => {
  const search = useLocation().search;

  useEffect(() => {
    localStorage.setItem("source", new URLSearchParams(search).get("s"));

    const amochat = document.querySelector(".amo-button-holder");
    if (amochat) {
      amochat.classList.add('d-none-important')
      
    }

    localStorage.setItem("promo", true);
  }, []);
  return (
    <ChecklistWrapper>
      <div className="checklist__main">
        <div className="desktop checklist__logo">
          <Logo
            style={{
              display: "inline-block",
              fontSize: "18px",
              cursor: "default",
            }}
          />
        </div>

        <div className="row space-between">
          <div className="checklist__leftside">
            <div className="mobile checklist__logo">
              <Logo
                style={{
                  display: "inline-block",
                  fontSize: "18px",
                  cursor: "default",
                }}
              />
            </div>
            <div className="checklist__title">
              <div>
                <span className="purple">Почему</span> бизнес
              </div>
              <div>может прогореть?</div>
            </div>
            <div className="checklist__subtitle">
              <span className="checklist__arrows">
                <img src={arrows} alt="" />
              </span>
              <div>
                Скачайте чек-лист, чтобы узнать{" "}
                <span className="purple">
                  как <br />
                  избежать ошибки и повысить продажи
                </span>
              </div>
            </div>
            <Link
              to="/checklist/mistakes/form"
              className="desktop button button_rectangle"
              style={{ width: "290px" }}
            >
              Скачать чек-лист
            </Link>
          </div>
          <div className="checklist__rightside">
            <div className="checklist__rightside__img-container">
              <img className="img" src={img} alt="" />
            </div>
            <span className="checklist__rightside__bottom-title">
              <span>mistake</span>
              <span>mistake</span>
              <span>mistake</span>
              <span>mistake</span>
              <span>mistake</span>
            </span>
            <Link
              to="/checklist/mistakes/form"
              className="mobile button button_rectangle"
              style={{ width: "290px" }}
            >
              Скачать чек-лист
            </Link>
          </div>
        </div>
      </div>
    </ChecklistWrapper>
  );
};

export default Mistakes;
