import React, { useEffect, useState } from 'react'

import Button from './generic/Button';

import './Calculator.css'
import { PANEL_LINK, panelLinks } from '../utils/consts';

const Calculator = ({ pageName, forwardRef }) => {

    const calcBudget = () => {
        return views * 0.989 * (pageName == 'blogger' ? 2 : 1);
    }

    const [views, setViews] = useState(20000)
    const [budget, setBudget] = useState(calcBudget().toString())

    const onChangeFunction = (e) => {
        const el = e.target;
        setViews(e.target.value)
        el.style.setProperty("--value", el.value);
        el.style.setProperty("--min", el.min === "" ? "0" : el.min);
        el.style.setProperty("--max", el.max === "" ? "100" : el.max);
        el.style.setProperty("--value", el.value);
    };

    useEffect(() => {
        setBudget(Math.round(calcBudget()).toString())
    }, [views])

    useEffect(() => {
        return () => setViews(20000)
    }, [])

    return (
        <section ref={forwardRef} className='calculator'>
            <div className='calculator__wrapper'>
                <div className='calculator__header'>
                    <h2>
                        <span>рассчитай</span>
                        <span className='pink'>стоимость</span>
                        <span>размещения</span>
                    </h2>
                </div>
                <div className='calculator__body'>
                    <div className='calculator__input'>
                        <span>
                            {pageName != 'blogger' ?
                                'Количество просмотров'
                                :
                                'Количество подписчиков'
                            }
                        </span>
                        <span className='purple'>{views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</span>
                    </div>
                    <input type='range' onChange={e => onChangeFunction(e)} min={1000} max={50000} step={1000} value={views} />

                    <div className='calculator__result'>
                        <div>ВАШ{pageName == 'blogger' ? ' ДОХОД ' : ' БЮДЖЕТ '}ОТ:</div>
                        <span>
                            {budget.split(/(?=(?:...)*$)/)[0]}
                            <span style={{ marginLeft: '10px' }} />
                            {budget.split(/(?=(?:...)*$)/)[1]}
                            <span className='rub'>₽</span>
                        </span>
                    </div>
                    <Button onClick={() => {ym(91389906,'reachGoal','btn-panel'); window.location.assign(panelLinks.login)}} className='desktop'>{pageName != 'blogger' ? 'разместить' : 'забрать'} рекламу</Button>
                </div>
            </div>
            <Button onClick={() => {ym(91389906,'reachGoal','btn-panel'); window.location.assign(panelLinks.login)}} className='mobile'>{pageName != 'blogger' ? 'разместить' : 'забрать'} рекламу</Button>
        </section>
    )
}

export default Calculator
