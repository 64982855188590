import React, { useEffect, useState, useRef, Fragment } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import "./App.css";

import Main from "./pages/Main";
import Blogger from "./pages/Blogger";
import Advertiser from "./pages/Advertiser";
import BurgerMenu from "./components/BurgerMenu";
import Error404 from "./pages/Error404";
import { panelLinks } from "./utils/consts";
import LeaveAlertModal from "./components/modals/LeaveAlertModal";

import Mistakes from "./pages/mistakes/Mistakes";
import MistakesForm from "./pages/mistakes/MistakesForm";
import MistakesEnd from "./pages/mistakes/MistakesEnd";




const App = ({ hideLoader }) => {
  const search = useLocation().search;
  let utm_source = new URLSearchParams(search).get("utm_source");
  const utm_medium = new URLSearchParams(search).get("utm_medium");
  const utm_campaign = new URLSearchParams(search).get("utm_campaign");
  const utm_term = new URLSearchParams(search).get("utm_term");
  const utm_content = new URLSearchParams(search).get("utm_content");

  useEffect(() => {
    if (utm_source) {
      if (utm_campaign) utm_source = utm_source + '_' + utm_campaign
      if (utm_content) utm_source = utm_source + '_' + utm_content
      if (utm_term) utm_source = utm_source + "_" + utm_term;
      document.cookie = `utm=${utm_source}; domain=bloggery.io`;
      panelLinks.login = `https://panel.bloggery.io/login?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`;
      panelLinks.register = `https://panel.bloggery.io/register?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`;
    }
  }, [search]);

  const [loading, setLoading] = useState(true);

  const [leaveAlertShow, setLeaveAlertShow] = useState(false);

  const handleMouseOut = () => {
    if (localStorage.getItem("promo")) return;
    setLeaveAlertShow(true);
    localStorage.setItem("promo", true);
  };

  useEffect(() => {
    let timeout;
    const onPageLoad = () => {
      console.log("page loaded");
      setLoading(false);
      document.getElementById("loader").classList.add("loader__hide");

      if (screen.width > 560) {
        timeout = window.setTimeout(() => {
          document.addEventListener("mouseleave", handleMouseOut);
        }, 3000);
      } else {
        timeout = window.setTimeout(() => {
          handleMouseOut();
        }, 12000);
      }
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }

    return () => window.clearTimeout(timeout);
  }, []);

  return (
    <>
      {loading ? (
        <span />
      ) : (
        <div className="app" id="app">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/blogger" element={<Blogger />} />
            <Route path="/advertiser" element={<Advertiser />} />
            
            <Route path="/checklist/mistakes" element={<Mistakes />} />
            <Route path="/checklist/mistakes/form" element={<MistakesForm />} />
            <Route path="/checklist/mistakes/end" element={<MistakesEnd />} />

            <Route path="/*" element={<Error404 />} />
          </Routes>
          <LeaveAlertModal
            show={leaveAlertShow}
            onHide={() => setLeaveAlertShow(false)}
          />
        </div>
      )}
    </>
  );
};

export default App;
