import React, { useState } from 'react'

import './Footer.css'
import Logo from './Logo'

import { TELEGRAM_LINK, VK_LINK, VCRU_LINK, PANEL_LINK } from '../utils/consts'

import telegram from '../assets/media/logo_telegram.png'
import vk from '../assets/media/logo_vk.png'
import vcru from '../assets/media/logo_vcru.png'
import LawInfo from './modals/LawInfo'
import { useNavigate } from 'react-router-dom'

import privacyPolicy from '../assets/privacy_policy.pdf'
import platformPolicy from '../assets/platform_policy.pdf'

const Footer = ({ advantagesRef, feedbackRef, faqRef, forwardRef }) => {

    const navigate = useNavigate()

    const scrollToAdvantages = () => advantagesRef.current.scrollIntoView()
    const scrollToFeedback = () => feedbackRef.current.scrollIntoView()
    const scrollToFaq = () => faqRef.current.scrollIntoView()

    const [visible, setVisible] = useState(false)


    return (
        <footer ref={forwardRef} className='footer'>
            <div className="footer__wrapper">
                <div className='footer__nav'>
                    <div>
                        <span onClick={() => { navigate('/'); window.scrollTo({ top: 0, left: 0, behavior: 'instant' }); }} >Главная</span>
                        <span onClick={() => { navigate('/blogger'); window.scrollTo({ top: 0, left: 0, behavior: 'instant' }); }} >Блогерам</span>
                        <span onClick={() => { navigate('/advertiser'); window.scrollTo({ top: 0, left: 0, behavior: 'instant' }); }} >Рекламодателям</span>
                        <span onClick={() => window.open('https://t.me/markberozkin', '_blank')}>Карьера</span>
                    </div>
                    <div>
                        <span onClick={() => window.location.assign(PANEL_LINK)}>Платформа</span>
                        <span onClick={scrollToAdvantages} >Преимущества</span>
                        <span onClick={scrollToFeedback} >Отзывы</span>
                        <span onClick={scrollToFaq} >FAQ</span>
                    </div>
                </div>
                <div className="footer__logo">
                    <Logo onClick={() => window.scrollTo(0, 0)} />
                    <span className='footer__logo__subtitle'>Реклама у блогеров для каждого</span>
                    <div className='desktop footer__law'>
                        <span><a href={platformPolicy} target='_blank'>Пользовательское соглашение</a></span>
                        <span><a href={privacyPolicy} target='_blank'>Политика конфиденциальности</a></span>
                        <span onClick={() => setVisible(true)} >Юридическая информация</span>
                    </div>

                </div>
                <div className="footer__contact">
                    <div className='footer__media'>
                        <div>
                            <a href={TELEGRAM_LINK} target='_blank' rel='noopener'><img className='img'src={telegram} alt="Telegram" /></a>
                        </div>
                        <div>
                            <a href={VK_LINK} target='_blank' rel='noopener'><img className='img' src={vk} alt="VK" /></a>
                        </div>
                        <div>
                            <a href={VCRU_LINK} target='_blank' rel='noopener'><img className='img' src={vcru} alt="vc.ru" /></a>
                        </div>
                    </div>
                    <div className='footer__contact_contacts'>
                        <span><a href='tel:+74994503717'>+ 7 (499) 450-37-17</a></span>
                        <span><a href='mailto:support@bloggery.io'>support@bloggery.io</a></span>
                    </div>
                </div>
                <div className='mobile footer__law'>
                    <span><a href={platformPolicy} target='_blank'>Пользовательское соглашение</a></span>
                    <span><a href={privacyPolicy} target='_blank'>Политика конфиденциальности</a></span>
                    <span onClick={() => setVisible(true)} >Юридическая информация</span>
                </div>
            </div>
            <LawInfo show={visible} onHide={() => setVisible(false)} />
        </footer>
    )
}

export default Footer
