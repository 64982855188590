import React from 'react';

import './Button.css';

const Button = ({className, style, onClick, disabled, children}) => {
  return (
    <button
      className={`button ${className || ''}`}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button;
