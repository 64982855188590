import React, { useRef, useState } from 'react';

import Container from '../components/generic/Container';
import Header from '../components/Header';
import Title from '../components/Title';
import Intro from '../components/Intro'
import About from '../components/About'
import Advantages from '../components/advantages/Advantages';
import UserFlow from '../components/UserFlow';
import Calculator from '../components/Calculator';
import Feedback from '../components/feedback/Feedback';
import Platforms from '../components/Platforms';
import FAQ from '../components/FAQ';
import End from '../components/End';
import Footer from '../components/Footer';

import AppearFromY from '../components/generic/AppearFromY';

import { useIntersectionObserver } from '../hooks/useIntersectionObserver';
import BurgerMenu from '../components/BurgerMenu';

const Advertiser = () => {

    const toggleBurgerVisible = () => {
        setBurgerVisible(!burgerVisible)
    }
    const headerRef = useRef()
    const burgerRef = useRef()
    const [burgerVisible, setBurgerVisible] = useState(false)

    const introRef = useRef();
    const introDataRef = useIntersectionObserver(introRef, { freezeOnceVisible: true })

    const aboutRef = useRef();
    const aboutDataRef = useIntersectionObserver(aboutRef, { freezeOnceVisible: true })

    const advantagesRef = useRef();
    const advantagesDataRef = useIntersectionObserver(advantagesRef, { freezeOnceVisible: true })

    const userflowRef = useRef();
    const userflowDataaRef = useIntersectionObserver(userflowRef, { freezeOnceVisible: true })

    const calculatorRef = useRef();
    const calculatorDataRef = useIntersectionObserver(calculatorRef, { freezeOnceVisible: true })

    const feedbackRef = useRef();
    const feedbackDataRef = useIntersectionObserver(feedbackRef, { freezeOnceVisible: true })

    const platformsRef = useRef();
    const platformsDataRef = useIntersectionObserver(platformsRef, { freezeOnceVisible: true })

    const faqRef = useRef();
    const faqDataRef = useIntersectionObserver(faqRef, { freezeOnceVisible: true })

    const endRef = useRef();
    const endDataRef = useIntersectionObserver(endRef, { freezeOnceVisible: true })

    return (
        <Container>

            <AppearFromY translateFrom={'-50px'} dataRef={'none'} >
            <Header visible={burgerVisible} toggleBurgerVisible={toggleBurgerVisible} forwardRef={headerRef} advantagesRef={advantagesRef} feedbackRef={feedbackRef} faqRef={faqRef} />
            </AppearFromY>

            <main>
                <AppearFromY delay={0} duration={200} dataRef={'none'} >
                    <Title calcRef={calculatorRef} pageName={'advertiser'} newPhrases={['ЭФФЕКТИВНО', 'БЫСТРО', 'ВЫГОДНО']} />
                </AppearFromY>

                <AppearFromY className='relative_z-2' dataRef={introDataRef} >
                        <Intro pageName={'advertiser'} forwardRef={introRef} />
                </AppearFromY>

                <AppearFromY dataRef={aboutDataRef} >
                    <About forwardRef={aboutRef} />
                </AppearFromY>

                <AppearFromY dataRef={advantagesDataRef} >
                    <Advantages forwardRef={advantagesRef} />
                </AppearFromY>

                <AppearFromY dataRef={userflowDataaRef} >
                    <UserFlow forwardRef={userflowRef} />
                </AppearFromY>

                <AppearFromY dataRef={calculatorDataRef} >
                    <Calculator forwardRef={calculatorRef} />
                </AppearFromY>

                <AppearFromY dataRef={feedbackDataRef} >
                    <Feedback forwardRef={feedbackRef} />
                </AppearFromY>

                <AppearFromY dataRef={platformsDataRef} >
                    <Platforms forwardRef={platformsRef} />
                </AppearFromY>

                <AppearFromY dataRef={faqDataRef} >
                    <FAQ pageName={'advertiser'} forwardRef={faqRef} />
                </AppearFromY>

                <AppearFromY dataRef={endDataRef} >
                    <End pageName={'advertiser'} forwardRef={endRef} />
                </AppearFromY>

            </main>


            <Footer advantagesRef={advantagesRef} feedbackRef={feedbackRef} faqRef={faqRef} />
            <BurgerMenu style={{top: headerRef.current?.clientHeight + 'px'}} forwardRef={burgerRef} visible={burgerVisible} advantagesRef={advantagesRef} feedbackRef={feedbackRef} faqRef={faqRef} />


        </Container>
    )
}

export default Advertiser
