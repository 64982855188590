import React, { useEffect, useRef, useState } from 'react'

import './LeaveAlertModal.css'
import Button from '../generic/Button'

import privacy_policy from '../../assets/privacy_policy.pdf'
import img from '../../assets/leave_alert_arrows.png'
import axios from 'axios'


const LeaveAlertModal = ({ show, onHide }) => {

  const ref = useRef()
  const handleClose = (e) => {
    if (!e) {
      onHide()
      return
    }
    if (ref.current && !ref.current.contains(e.target)) {
      onHide()
      return
    }
  }

  const [phone, setPhone] = useState('')
  const [phoneDirty, setPhoneDirty] = useState(false)
  const [phoneError, setPhoneError] = useState('Поле не может быть пустым')

  const handlePhone = (e) => {
    setPhone(e.target.value)
    let re = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
    if (!re.test(String(e.target.value))) {
      setPhoneError('Неверный формат телефона')
    } else {
      setPhoneError('')
    }

  }

  const handleSubmit = async () => {
    await axios.post('https://bloggery-backend.server3.bloggery.io/api/other/subscribePhone', { phone, email: 'no' })
      .then(res => {
        if (res.data.ok) onHide()
      })
  }


  return (
    <div
      className='leave-alert'
      onClick={(e) => handleClose(e)} style={show ? {} : { display: 'none' }}
    >
      <div ref={ref} className='leave-alert__content'>
        <div className='leave-alert__content_inner' >
          <button style={{ border: 'none' }} onClick={() => handleClose()} className='leave-alert__close'>
            <span>X</span>
          </button>
          <span className='leave-alert__header'>BLOGGERY</span>
          <div className='leave-alert__body'>
            <div className='leave-alert__body__intro'>
              <span>МЫ ТУТ!</span>
            </div>
            <div className='leave-alert__body__text'>
              Наша подборка блогеров<br />гарантированно <span className='text_gradient'>повысит<br />твои продажи!</span>
            </div>
            <div className='leave-alert__body__input'>
              {(phoneDirty && phoneError) &&
                <span style={{ color: '#FF3A3A', fontSize: '12px', marginBottom: '8px' }} >{phoneError}</span>
              }
              <input
                value={phone}
                onBlur={() => setPhoneDirty(true)}
                onChange={(e) => handlePhone(e)}
                placeholder='+7 (999) 999-99-99'
                type='tel'
              />
              <Button
                onClick={handleSubmit}
                className='button button_primary'
                disabled={phoneError ? true : false}
              >
                БЕСПЛАТНАЯ ПОДБОРКА
              </Button>
              <span style={{ color: '#8C8C8C', fontSize: '12px' }}>
                Нажимая на кнопку, вы соглашаетесь
              </span>
              <span style={{ color: '#515CD1', fontSize: '12px' }}>
                <a href={privacy_policy} target='_blank'>
                  с политикой конфиденциальности
                </a>
              </span>
            </div>

          </div>
          <img src={img} alt="" className='leave-alert__body__img' />

        </div>

      </div>
    </div>
  )
}

export default LeaveAlertModal
