import React from "react";

import "./Intro.css";

import introArrows from "../assets/intro_arrows.png";
import { Link } from "react-router-dom";

import { panelLinks } from "../utils/consts";

const Intro = ({ forwardRef, pageName }) => {
  return (
    <section ref={forwardRef} className="intro" style={{ zIndex: "10" }}>
      <div className="intro__wrapper">
        <div className="intro__main">
          {pageName == "main" && (
            <>
              <div className="intro__card intro__blogger">
                <h3>ХОТИТЕ ЗАРАБОТАТЬ?</h3>
                <h2>БЛОГЕРАМ</h2>
                <div className="intro__card-text">
                  <div>
                    <span className="intro__card-text_decor"></span>
                    <span>
                      Экономим время - подберем рекламодателей без вашего
                      участия;
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor purple"></span>
                    <span>
                      Больше не нужно каждому отправлять свою статистику, не
                      получая от них ответа;
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor"></span>
                    <span>
                      Обеспечиваем стабильный доход - Вы получаете реальные
                      деньги{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        с быстрым выводом;
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor purple"></span>
                    <span>Помогаем расти маленьким блогам;</span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor"></span>
                    <span>
                      Гарантируем честную и безопасную сделку с рекламодателем;
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor purple"></span>
                    <span>
                      Самостоятельно взаимодействуем с ОРД.
                      <br />
                      Наслаждайтесь творчеством - маркировкой рекламы{" "}
                      <span style={{ whiteSpace: "nowrap" }}>займемся мы.</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="intro__card intro__advertiser">
                <h3>ХОТИТЕ УВЕЛИЧИТЬ ПРОДАЖИ?</h3>
                <h2>РЕКЛАМОДАТЕЛЯМ</h2>
                <div className="intro__card-text">
                  <div>
                    <span className="intro__card-text_decor"></span>
                    <span>
                      Простой интерфейс, встроенная нейросеть и наши менеджеры
                      помогут Вам бесплатно найти блогера и составить{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        сценарий рекламы;
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor purple"></span>
                    <span>
                      Собрали все данные о блогере в одном месте, больше не
                      нужно искать вручную и проверять данные по всем{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        сервисам и чатам;
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor"></span>
                    <span>
                      Самостоятельно взаимодействуем с ОРД. Создавайте рекламные
                      кампании - маркировкой рекламы займемся мы;
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor purple"></span>
                    <span>
                      Обеспечиваем гарантию честной и безопасной сделки.
                      <br />
                      Блогер получит оплату только после выхода рекламы и
                      выполнения всех условия. Теперь вы точно получите
                      статистику после рекламы;
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor"></span>
                    <span>
                      Предоставляем необходимые документы для бухгалтерии.
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}

          {pageName == "blogger" && (
            <>
              <div
                className="intro__card intro__blogger blogger"
                style={{ margin: 0 }}
              >
                <h2>
                  Зачем{" "}
                  <span
                    style={{ fontSize: "inherit" }}
                    className="text_gradient"
                  >
                    блогеру
                  </span>
                  <br />
                  регистрироваться на сайте?
                </h2>

                <div className="intro__card-text">
                  <div>
                    <span className="intro__card-text_decor"></span>
                    <span>
                      Размещаем рекламные предложения только от проверенных и
                      надежных заказчиков;
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor purple"></span>
                    <span>
                      Экономим ваше время - подберем новых рекламодателей{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        без вашего участия.
                      </span>
                      <br />
                      Больше не нужно каждому отправлять свою статистику, не
                      получая{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        от них ответа;
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor"></span>
                    <span>
                      Обеспечиваем стабильный доход - Вы получаете реальные
                      деньги{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        с быстрым выводом;
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor purple"></span>
                    <span>
                      Самостоятельно выполняем требования{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        ФЗ “О рекламе”
                      </span>{" "}
                      и взаимодействуем с ОРД.
                      <br />
                      Наслаждайтесь творчеством - маркировкой рекламы займемся
                      мы;
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor"></span>
                    <span>
                      Предоставляем возможность работать{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        с крупными брендами;
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor purple"></span>
                    <span>
                      Гарантируем честную и безопасную сделку{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        с рекламодателем;
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor"></span>
                    <span>Помогаем расти маленьким блогам;</span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor purple"></span>
                    <span>
                      Бесплатное обучение искусству продавать результативно,
                      креативно и нативно.
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}

          {pageName == "advertiser" && (
            <>
              <div
                className="intro__card intro__advertiser advertiser"
                style={{ margin: 0 }}
              >
                <h2>
                  Что получает{" "}
                  <span
                    style={{ fontSize: "inherit" }}
                    className="text_gradient"
                  >
                    рекламодатель
                  </span>
                  <br />
                  при регистрации на сайте?
                </h2>
                <div className="intro__card-text">
                  <div>
                    <span className="intro__card-text_decor"></span>
                    <span>
                      Гарантируем проверенную базу блогеров - перед регистрацией
                      каждый исполнитель проходит модерацию и проверяется по
                      черным спискам других сервисов;
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor purple"></span>
                    <span>
                      Обеспечиваем гарантию честной и безопасной сделки. При
                      заключении сделки блогер получает оплату за интеграцию
                      после завершения{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        рекламной кампании;
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor"></span>
                    <span>
                      Простой интерфейс, встроенная нейросеть и наши менеджеры
                      помогут Вам бесплатно найти блогера и составить{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        сценарий рекламы;
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor purple"></span>
                    <span>
                      Формируем нейросетью уникальную подборку блогеров.
                      Заполните свое рекламное предложение и на основе этих
                      данных сгенерируем подборку блогеров персонально для Вас;
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor"></span>
                    <span>
                      Обеспечиваем соблюдение{" "}
                      <span style={{ whiteSpace: "nowrap", fontSize: "0.9em" }}>
                        ФЗ “О рекламе”.
                      </span>{" "}
                      Вы работаете с блогерами - мы занимаемся{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        маркировкой рекламы;
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="intro__card-text_decor purple"></span>
                    <span>
                      Предоставляем необходимые документы для бухгалтерии.
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className={"intro__footer"}>
          <div className={"intro__footer__inner"}>
            <img
              className={"img intro__footer__img"}
              src={introArrows}
              alt=""
            />
            <h3>
              Получи<span className="purple"> гарантированный охват </span>
              <br />
              новой аудитории для своего бизнеса
            </h3>
            <Link
              style={{ margin: "40px 0" }}
              className="button button_primary"
              to={panelLinks.register}
            >
              попробовать бесплатно
            </Link>
          </div>
          <Link
            style={{ margin: "40px 0" }}
            className="mobile button button_primary"
            to={panelLinks.register}
          >
            разместить рекламу
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Intro;
