import React from 'react'

import img from '../assets/platforms_img.webp'
import mobile from '../assets/platforms_mobile.webp'

import './Platforms.css'
const Platforms = ({forwardRef}) => {
  return (
    <section ref={forwardRef} className='platforms'>
      <div className='platforms__wrapper'>
        <h2>МЫ РАБОТАЕМ</h2>
        <div className='platforms__img'>
          <img className='desktop img' src={img} alt="" />
          <img className='mobile img' src={mobile} alt="" />
        </div>
      </div>
    </section>
  )
}

export default Platforms
