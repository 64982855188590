import React from 'react'
import Button from './generic/Button'

import img from '../assets/about_img.webp'
import arrow from '../assets/arrows.webp'
import './About.css'
import { PANEL_LINK, panelLinks } from '../utils/consts'

const About = ({ pageName, forwardRef }) => {
  return (
    <section ref={forwardRef} className='about'>
      <span className='about__light' />
      <div className='about__wrapper'>

        <div className='about__title'>
          <h2 className='h1-like-title'>BLOGGERY.IO</h2>
          <div className='about__subtitle'>
            <h3>рекламный маркетплейс<br /> блогеров и рекламодателей</h3>
          </div>
        </div>

        <div className='about__body'>
          <div className='about__text1'>
            <img src={arrow} alt={''} className='title__decor_arrow' />
            <h4>
              <span>продажа</span><br />
              <span>через блогеров</span>
            </h4>
            <div>
              это не только эффективный, но <br />и самый выгодный <br id='spec' />способ продаж!
            </div>
            <span className='about__photos' />
          </div>

          <div className='about__img-container'>
            <img src={img} alt="" className="img about__img" />
          </div>


          <div className='about__text2'>
            <span className='about__planet' />
            <span className='about__like' />
            <h4>[ У НАС ВЫ МОЖЕТЕ ]</h4>
            <ol>
              <li>{pageName == 'blogger' ? 'зарабатывать' : 'искать необходимого блогера'}</li>
              <li>{pageName == 'blogger' ? 'находить рекламодателя' : 'размещать предложение о рекламе'}</li>
              <li>{pageName == 'blogger' ? 'принимать предложения о рекламе' : 'смотреть и изучать статистику'}</li>
              <li>общаться в чате</li>
              <li>заполнять техническое задание онлайн</li>
              <li>обращаться в службу поддержки</li>
            </ol>
            <Button onClick={() => {ym(91389906,'reachGoal','btn-panel'); window.location.assign(panelLinks.register)}} >зарегистрироваться</Button>
          </div>
        </div>

      </div>
    </section>
  )
}

export default About
