import React from 'react'


import './FAQ.css'
const FAQ = ({ pageName, forwardRef }) => {
  return (
    <section ref={forwardRef} className='faq'>
      <div className='faq__wrapper'>
        <h2>ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h2>

        {pageName == 'main' &&
          <div className="faq__body">

            <details>
              <summary>
                <span className='num'>01</span>
                <h3>что выделяет <span className='text_gradient'>Bloggery</span> среди остальных?</h3>
                <span className='arrow' />
              </summary>
              <p>
                У нас собраны лучшие блогеры и рекламные предложения, а заботливая служба поддержки всегда рада Вам помочь!
              </p>
            </details>

            <details>
              <summary>
                <span className='num'>02</span>
                <h3>Зачем <span className='text_gradient'>Bloggery</span> для блогера?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Монетизируете свой блог с быстрым выводом денежных средств, экономя при этом время на поиски рекламодателей и ускоряя коллаборации с известными брендами!
              </p>
            </details>

            <details>
              <summary>
                <span className='num'>03</span>
                <h3>Зачем <span className='text_gradient'>Bloggery</span>  для рекламодателя?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Мы уже все сделали за вас – собрали лучших блогеров с адекватной стоимостью рекламы! Вам остается лишь выбрать интересующего Вас блогера и сделать с ним коллаборацию!
              </p>
            </details>

            <details>
              <summary>
                <span className='num'>04</span>
                <h3>Как происходит процесс оплаты?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Мы внедрили сервис безопасной сделки – рекламодатель вносит оплату до публикации рекламы, а блогер может вывести деньги только после успешной интеграции!               </p>
            </details>

            <details>
              <summary>
                <span className='num'>05</span>
                <h3>Какая стоимость размещения рекламы на сайте?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Регистрация и пользование внутренними сервисами платформы - бесплатно! Совершая сделки, вы получаете доступ к базе блогеров и аукционным предложениям.
              </p>
            </details>

            <details>
              <summary>
                <span className='num'>06</span>
                <h3>С какими социальными сетями <span className='text_gradient'>Bloggery</span> работает?</h3>
                <span className='arrow' />
              </summary>
              <p>
                У нас можно заказать рекламу во всех социальных сетях, включая: ВКонтакте, Telegram, YouTube, Twitch, Instagram* <span className='text_footnote'>(признана экстремистской и запрещена на территории РФ)</span> и др.
              </p>
            </details>
            <details>
              <summary>
                <span className='num'>07</span>
                <h3>Если мне что-то не понравится?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Сайт сейчас находится в разработке, любые недочеты или вопросы вы можете написать в техническую поддержку. Мы рады любой обратной связи,  ваши обращения помогают нам становиться ближе к пользователям и улучшать качество работы.
              </p>
            </details>
            <details>
              <summary>
                <span className='num'>08</span>
                <h3>Как попасть блогеру в базу данных?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Зарегистрироваться может абсолютно любой блогер, но мы оставляем за собой право модерации блогеров с аудиторией до 5к подписчиков! Практика показывает, что даже у блогеров с 1к подписчиками есть качественная и <span style={{whiteSpace:'nowrap'}}>платежеспособная аудитория!</span> Регистрируйся, мы подскажем тебе как увеличить охваты!
              </p>
            </details>

          </div>
        }

        {pageName == 'blogger' &&
          <div className="faq__body">

            <details>
              <summary>
                <span className='num'>01</span>
                <h3>что выделяет <span className='text_gradient'>Bloggery</span> среди остальных?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Наконец-то можешь забыть о постоянных запросов статистики,
                решении технических вопросов, поисках рекламодателей!<br />
                Мы самостоятельно найдем рекламодателей, теперь можешь спокойно
                сконцентрироваться на контенте!
              </p>
            </details>

            <details>
              <summary>
                <span className='num'>02</span>
                <h3>Сколько подписчиков мне нужно, чтобы стать частью <span className='text_gradient'>BLOGGERY</span>?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Зарегистрироваться может абсолютно любой блогер, но мы оставляем за собой право модерации блогеров с аудиторией до 5к подписчиков! Практика показывает, что даже у блогеров с 1к подписчиками есть качественная и платежеспособная аудитория! Регистрируйся, мы подскажем тебе как увеличить охваты!
              </p>
            </details>

            <details>
              <summary>
                <span className='num'>03</span>
                <h3>Когда я получу оплату?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Денежные средства от рекламодателей замораживаются на твоем
                аккаунте до момента публикации рекламы в твоем блоге, как только
                выполнены все условия, оплата поступает на твой банковский счет в
                течение 3х дней!
              </p>
            </details>

            <details>
              <summary>
                <span className='num'>04</span>
                <h3>С какими социальными сетями <span className='text_gradient'>Bloggery</span> работает?</h3>
                <span className='arrow' />
              </summary>
              <p>
                У нас можно заказать рекламу во всех социальных сетях, включая: ВКонтакте, Telegram, YouTube, Twitch, Instagram* <span className='text_footnote'>(признана экстремистской и запрещена на территории РФ)</span> и др.
              </p>
            </details>

          </div>
        }

        {pageName == 'advertiser' &&
          <div className="faq__body">

            <details>
              <summary>
                <span className='num'>01</span>
                <h3>Зачем работать с блогерами?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Вопрос очень интересный! Если Вы до сих пор не рекламировали свой
                бизнес у блогеров, то Вы многое потеряли! Покупки через
                инфлюенсеров позволяют не только увеличивать узнаваемость бренда
                и охваты, но увеличивают продажи!<br/>Так, по итогам 2022 года рынок
                инфлюенс-маркетинга вырос на 20% и достиг отметки в 22 млрд. руб.!
              </p>
            </details>

            <details>
              <summary>
                <span className='num'>02</span>
                <h3>Как связаться с блогерами?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Регистрируетесь на нашей платформе, выбирает нужного для Вашего
                сегмента блогера и пишете ему на нашей платформе!<br/>Мы сделали все,
                чтобы этот процесс был удобным и простым!
              </p>
            </details>

            <details>
              <summary>
                <span className='num'>03</span>
                <h3>Какой минимальный бюджет для рекламы?</h3>
                <span className='arrow' />
              </summary>
              <p>
                На этот вопрос у нас нет однозначного ответа, поскольку на нашей
                платформе зарегистрированы представители малого бизнеса, среднего
                и крупного бизнеса! Бюджет формируется в зависимости от
                потребностей и запросов, для одних это <span style={{whiteSpace: 'nowrap'}}>1000 рублей</span>, а для других 
                <span style={{whiteSpace: 'nowrap'}}> 100 000 рублей</span>! Поэтому ответим, что минимальный бюджет - 1000
                рублей!
              </p>
            </details>

            <details>
              <summary>
                <span className='num'>04</span>
                <h3>Как происходит процесс оплаты?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Мы внедрили сервис безопасной сделки – рекламодатель вносит оплату до публикации рекламы, а блогер может вывести деньги только после успешной интеграции!
              </p>
            </details>

            <details>
              <summary>
                <span className='num'>05</span>
                <h3>Предоставляете ли Вы документы для бухгалтерии?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Да, конечно, напишите в нашу службу поддержки и мы пришлем Вам
                все закрывающие документы! В скором времени этот процесс будет
                автоматическим и Вы сможете выгружать их в личном кабинете!
              </p>
            </details>

            <details>
              <summary>
                <span className='num'>06</span>
                <h3>С какими социальными сетями <span className='text_gradient'>Bloggery</span> работает?</h3>
                <span className='arrow' />
              </summary>
              <p>
                У нас можно заказать рекламу во всех социальных сетях, включая: ВКонтакте, Telegram, YouTube, Twitch, Instagram* <span className='text_footnote'>(признана экстремистской и запрещена на территории РФ)</span> и др.
              </p>
            </details>
            <details>
              <summary>
                <span className='num'>07</span>
                <h3>Как связаться с блогером?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Регистрируетесь на нашей платформе, самостоятельно выбираете блогера из базы данных или отправляете свое рекламное предложение на аукцион. Мы создаем сервис, где все процессы проходят просто и доступно для любого пользователя!
              </p>
            </details>
            <details>
              <summary>
                <span className='num'>08</span>
                <h3>Если реклама не сработает?</h3>
                <span className='arrow' />
              </summary>
              <p>
                Успех процесса рекламной интеграции зависит не только от работы нашего сервиса, но и от Вашего взаимодействия с блогером! Но, мы можем помочь улучшить результаты последующих кампаний - для пользователей сервиса доступна услуга “аудит рекламы”, при помощи которой вы узнаете, как улучшить свои показатели. Совершите у нас рекламную интеграцию и получите доступ к этой услуге!
              </p>
            </details>

          </div>
        }

      </div>
    </section>
  )
}

export default FAQ
