export const advantages = [
    {
        title: 'Ответственность',
        text: 'Автоматически передаем<br/>данные в ОРД',
    },
    {
        title: 'Качество',
        text: 'Все блогеры проходят модерацию, проверяем на накрутки и упоминание в черных списках',
    },
    {
        title: 'Экономия',
        text: 'Инструменты сайта сохраняют ваши время и деньги. Делегируйте всю рутинную работу нам',
    },
    {
        title: 'Эффективность',
        text: 'Рейтинг блогеров на сайте создан по результатам предыдущих кампаний. Получайте результат быстро',
    },
    {
        title: 'Безопасность',
        text: 'Мы предоставляем 100% гарантию на все сделки',
    },
    {
        title: 'удобство',
        text: 'Нашим интерфейсом легко<br/>и удобно пользоваться<br/>со всех устройств',
    },
    {
        title: 'поддержка',
        text: 'Наша служба поддержки<br/>работает круглосуточно',
    },
    {
        title: 'сообщество',
        text: 'Мы объединяем лучших<br/>блогеров и рекламодателей',
    },
]
