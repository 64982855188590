import React from 'react'
import { SwiperSlide } from 'swiper/react'
import { advantages } from '../../utils/advantages'

import Container from '../generic/Container'
import Slider from '../generic/Slider'
import AdvantagesCard from './AdvantagesCard'


import './Advantages.css'


const Advantages = ({ forwardRef }) => {

  return (
    <section ref={forwardRef} className='advantages'>
      <div className="advantages__wrapper">
        <h2>наши преимущества</h2>

        <Slider className='swiper-container' slidesPerView='auto'>
          {advantages.map((e, i) =>
            <SwiperSlide key={i}>
              <AdvantagesCard
                className={i % 2 ? 'AdvantagesCard__theme_filled' : ''}
                title={e.title}
                text={e.text}
              />
            </SwiperSlide>
          )}
        </Slider>

      </div>
    </section>
  )
}

export default Advantages
